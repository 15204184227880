import React from "react"
import SEO from "../components/seo"
import { graphql, Link as GatsbyLink } from "gatsby"
import { Footer, FooterLinks } from "../components/Layout/Footer"
import Layout from "../components/Layout"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Link from "@material-ui/core/Link"
import { AlertProvider } from "@xyo-network/coin-forms/build/lib/components/Alert"
import { WhacAuth } from "../components/Auth"
import kebabCase from "lodash/kebabCase"

export function EpisodeTemplate({ data }) {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <AlertProvider>
        <SEO
          title={post.frontmatter.title}
          meta={[
            {
              property: `og:image`,
              content:
                post.frontmatter.featuredImage.img.childImageSharp.fixed.src,
            },
          ]}
        />
        <Container style={{ paddingBottom: "20vh", paddingTop: 16 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <Box
                position="relative"
                style={{
                  paddingTop: "56.5%",
                  backgroundColor: "#000",
                }}
              >
                <iframe
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: 0,
                    right: 0,
                  }}
                  title={post.frontmatter.title}
                  src={post.frontmatter.embedSrc}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography variant="h4" gutterBottom>
                {post.frontmatter.title}
              </Typography>
              <Typography variant="caption" gutterBottom>
                {post.frontmatter.date}{" "}
                {post.frontmatter.tags ? (
                  <>
                    -{" "}
                    {post.frontmatter.tags.map((tag, i) => (
                      <span key={tag}>
                        {i ? ", " : ""}
                        <Link
                          component={GatsbyLink}
                          to={`/tags/${kebabCase(tag)}`}
                        >
                          {tag}
                        </Link>
                      </span>
                    ))}
                  </>
                ) : null}
              </Typography>
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
              {post.frontmatter.advancedId && (
                <Box textAlign="center" my={2}>
                  <WhacAuth advancedId={post.frontmatter.advancedId} />
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>
        <Footer />
        <FooterLinks />
      </AlertProvider>
    </Layout>
  )
}

export default EpisodeTemplate
export const pageQuery = graphql`
  query BlogPostByPath($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        tags
        title
        embedSrc
        description
        advancedId
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          img {
            childImageSharp {
              fixed(width: 1080, height: 565) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          alt
        }
      }
    }
  }
`
