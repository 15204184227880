import React from "react"
import { CoinAuth } from "@xyo-network/coin-opt-in/build/lib/components/Auth"
import { CoinLoginButton } from "@xyo-network/coin-opt-in/build/lib/components/Buttons"
import { scopedTokenApi } from "@xyo-network/coin-opt-in/build/lib/api"
import { useSetAlertSuccess, useSetAlertError } from "@xyo-network/coin-forms/build/lib/components/Alert"

// const bossUri = 'http://localhost:8083'
// const authUri = 'http://localhost:3002?redirectUri=http://localhost:8000/&clientId=5e332ed604189792deda1fac&scopes=drop-opt-in'
const bossUri = 'https://bossapi.coinapp.co'
const authUri = 'https://auth.coinapp.co?redirectUri=https://whac.coinapp.co/&clientId=5e332ed604189792deda1fac&scopes=drop-opt-in'
const api = scopedTokenApi((opts) => fetch(bossUri, opts));
export const WhacAuth = ({ advancedId }) => {
  const [enrolling, setEnrolling] = React.useState(false);
  const setSuccess = useSetAlertSuccess();
  const setError = useSetAlertError();
  const handleLogin = async (scopedToken) => {
    try {
      setEnrolling(true);
      const optIn = await api.advancedDropOptIn(advancedId, scopedToken);
      if (optIn) {
        setSuccess('You have successfully enrolled for WHAC geodrops! Open the app to collect your reward.');
      }
      window.coinTracker = window.coinTracker || []
      window.coinTracker.push({ event: "convert" })
    } catch (e) {
      console.log(e);
      setError(e.message);
    }
    setEnrolling(false);
  };
  return (
    <CoinAuth
      url={authUri}
      onSuccess={(scopedToken) => handleLogin(scopedToken)}
      onError={() => setError('Login Error')}
      renderButton={({ loading }, { openPopup }) => (
        <CoinLoginButton loading={enrolling || loading} onClick={openPopup}>
          {enrolling ? 'Enrolling in WHAC' : 'Login with COIN'}
        </CoinLoginButton>
      )}
    ></CoinAuth>
  );
};
